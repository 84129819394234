import { CustomButton } from "../../components/customButton";
import { CustomInput } from "../../components/customInput";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import { ICompany } from "../../@types/graphql";
import { toast } from "sonner";
import { useMutation } from "@apollo/client";
import { CREATE_COMPANY } from "../../api/graphql/companies";

import "../../styles/pages/company/company.css";

export const CreateCompanyPage: FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const navigate = useNavigate();
  const [createCompany, { loading }] = useMutation(CREATE_COMPANY);

  const defaultValues = useMemo(() => {
    return {
      id: "",
      name: "",
    };
  }, []);

  const form = useForm<ICompany>({
    onSubmit: async ({ value }) => {
      try {
        await createCompany({ variables: value });
        toast.success(
          !isEdit ? "Компания успешно создана" : "Компания успешно обновлена",
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    defaultValues,
  });

  return (
    <div className="company-create-page">
      <h2 className="mainTitle flex items-center gap-24">
        {isEdit ? "Редактирование компании" : "Новая компания"}
        <CustomButton onClick={() => navigate("/companies")}>
          Вернуться к списку
        </CustomButton>
      </h2>
      <form
        className="company-create-form"
        onSubmit={(e) => {
          e.preventDefault();
          form.handleSubmit();
        }}
      >
        <form.Field
          name="name"
          children={(field) => {
            return (
              <CustomInput
                required
                placeholder="Введите название компании"
                id={field.name}
                label="Название"
                value={field.state.value}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
            );
          }}
        />
        <div className="flex items-center gap-8">
          {isEdit && (
            <CustomButton
              disabled={loading}
              type="button"
              onClick={() => form.reset()}
              buttonView="outline"
              className="w-full"
            >
              Сбросить
            </CustomButton>
          )}
          <CustomButton disabled={loading} className="w-full">
            {isEdit ? "Сохранить" : "Создать"}
          </CustomButton>
        </div>
      </form>
    </div>
  );
};
